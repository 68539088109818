import {Popover, Transition} from '@headlessui/react';
import {
    ChartBarIcon,
    CursorClickIcon,
    MenuIcon,
    XIcon,
} from '@heroicons/react/outline';
import {ChevronDownIcon} from '@heroicons/react/solid';
import {Fragment, ReactElement} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import {useLocale} from '~/helpers/language-context';

import classNames from '../../helpers/classNames';
import Logo from '../Logo';

export default function MainMenu(): ReactElement {
    const locale = useLocale();
    const intl = useIntl();

    const menuLocations = [
        {
            name: intl.formatMessage({
                id: 'menu.locations.airports.title',
                defaultMessage: 'Airports',
            }),
            description: intl.formatMessage({
                id: 'menu.locations.airports.description',
                defaultMessage:
                    'Get a better understanding of where your traffic is coming from.',
            }),
            href: '#',
            icon: ChartBarIcon,
        },
        {
            name: intl.formatMessage({
                id: 'menu.locations.cities.title',
                defaultMessage: 'Cities',
            }),
            description: intl.formatMessage({
                id: 'menu.locations.cities.description',
                defaultMessage:
                    'Get a better understanding of where your traffic is coming from.',
            }),
            href: '#',
            icon: CursorClickIcon,
        },
        {
            name: intl.formatMessage({
                id: 'menu.locations.train.title',
                defaultMessage: 'Train Stations',
            }),
            description: intl.formatMessage({
                id: 'menu.locations.train.description',
                defaultMessage:
                    'Get a better understanding of where your traffic is coming from.',
            }),
            href: '#',
            icon: CursorClickIcon,
        },
        {
            name: intl.formatMessage({
                id: 'menu.locations.europe.title',
                defaultMessage: 'Europe',
            }),
            description: intl.formatMessage({
                id: 'menu.locations.europe.description',
                defaultMessage:
                    'Get a better understanding of where your traffic is coming from.',
            }),
            href: '#',
            hideMobile: true,
            icon: CursorClickIcon,
        },
        {
            name: intl.formatMessage({
                id: 'menu.locations.northamerica.title',
                defaultMessage: 'North America',
            }),
            description: intl.formatMessage({
                id: 'menu.locations.northamerica.description',
                defaultMessage:
                    'Get a better understanding of where your traffic is coming from.',
            }),
            href: '#',
            hideMobile: true,

            icon: CursorClickIcon,
        },
        {
            name: intl.formatMessage({
                id: 'menu.locations.southamerica.title',
                defaultMessage: 'South America',
            }),
            description: intl.formatMessage({
                id: 'menu.locations.southamerica.description',
                defaultMessage:
                    'Get a better understanding of where your traffic is coming from.',
            }),
            href: '#',
            hideMobile: true,

            icon: CursorClickIcon,
        },
        {
            name: intl.formatMessage({
                id: 'menu.locations.asia.title',
                defaultMessage: 'Asia & Australasia',
            }),
            description: intl.formatMessage({
                id: 'menu.locations.asia.description',
                defaultMessage:
                    'Get a better understanding of where your traffic is coming from.',
            }),
            href: '#',
            hideMobile: true,

            icon: CursorClickIcon,
        },
        {
            name: intl.formatMessage({
                id: 'menu.locations.africa.title',
                defaultMessage: 'Africa',
            }),
            description: intl.formatMessage({
                id: 'menu.locations.africa.description',
                defaultMessage:
                    'Get a better understanding of where your traffic is coming from.',
            }),
            href: '#',
            hideMobile: true,

            icon: CursorClickIcon,
        },
    ];

    const moreLinks = [
        {
            name: intl.formatMessage({
                id: 'menu.more.oneway.title',
                defaultMessage: 'One way',
            }),
            description: intl.formatMessage({
                id: 'menu.more.oneway.description',
                defaultMessage:
                    'Learn how to maximize our platform to get the most out of it.',
            }),
            href: '#',
        },
        {
            name: intl.formatMessage({
                id: 'menu.more.carhireexcess.title',
                defaultMessage: 'Car Hire Excess',
            }),
            description: intl.formatMessage({
                id: 'menu.more.carhireexcess.description',
                defaultMessage:
                    'Learn how to maximize our platform to get the most out of it.',
            }),
            href: '#',
        },
        {
            name: intl.formatMessage({
                id: 'menu.more.automatics.title',
                defaultMessage: 'Automatics',
            }),
            description: intl.formatMessage({
                id: 'menu.more.automatics.description',
                defaultMessage:
                    'Learn how to maximize our platform to get the most out of it.',
            }),
            href: '#',
        },
        {
            name: intl.formatMessage({
                id: 'menu.more.peoplecarries.title',
                defaultMessage: 'People Carriers',
            }),
            description: intl.formatMessage({
                id: 'menu.more.peoplecarries.description',
                defaultMessage:
                    'Learn how to maximize our platform to get the most out of it.',
            }),
            href: '#',
        },
    ];

    return (
        <div className="relative z-20 bg-gray-100">
            <div className="mx-auto max-w-7xl">
                <Popover className="relative">
                    <div className="flex items-center justify-between px-4 py-6 sm:px-6 md:justify-start md:space-x-10">
                        <div>
                            <Logo />
                        </div>
                        <div className="-my-2 -mr-2 md:hidden">
                            <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-pink-500">
                                <span className="sr-only">
                                    <FormattedMessage
                                        id="menu.menu.open"
                                        defaultMessage="Open Menu"
                                    />
                                </span>
                                <MenuIcon
                                    className="h-6 w-6"
                                    aria-hidden="true"
                                />
                            </Popover.Button>
                        </div>
                        <div className="hidden md:flex md:flex-1 md:items-center md:justify-between">
                            <Popover.Group as="nav" className="flex space-x-5">
                                <Popover className="relative">
                                    {({open}) => (
                                        <>
                                            <Popover.Button
                                                className={classNames(
                                                    open
                                                        ? 'bg-white text-gray-900'
                                                        : 'bg-gray-100 text-gray-500',
                                                    'btn',
                                                )}
                                            >
                                                <span>
                                                    <FormattedMessage
                                                        id="menu.locations"
                                                        defaultMessage="Locations"
                                                    />
                                                </span>
                                                <ChevronDownIcon
                                                    className={classNames(
                                                        open
                                                            ? 'text-gray-600'
                                                            : 'text-gray-400',
                                                        'ml-2 h-5 w-5 group-hover:text-gray-500',
                                                    )}
                                                    aria-hidden="true"
                                                />
                                            </Popover.Button>

                                            <Transition
                                                as={Fragment}
                                                enter="transition ease-out duration-200"
                                                enterFrom="opacity-0 translate-y-1"
                                                enterTo="opacity-100 translate-y-0"
                                                leave="transition ease-in duration-150"
                                                leaveFrom="opacity-100 translate-y-0"
                                                leaveTo="opacity-0 translate-y-1"
                                            >
                                                <Popover.Panel className="absolute z-10 -ml-4 mt-3 w-screen max-w-md transform lg:max-w-3xl">
                                                    <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                                                        <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8 lg:grid-cols-2">
                                                            {menuLocations.map(
                                                                (item) => (
                                                                    <a
                                                                        key={
                                                                            item.name
                                                                        }
                                                                        href={
                                                                            item.href
                                                                        }
                                                                        className="-m-3 flex items-start rounded-lg p-3 hover:bg-gray-50"
                                                                    >
                                                                        <div className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-md bg-pink-500 text-white sm:h-12 sm:w-12">
                                                                            <item.icon
                                                                                className="h-6 w-6"
                                                                                aria-hidden="true"
                                                                            />
                                                                        </div>
                                                                        <div className="ml-4">
                                                                            <p className="text-base font-medium text-gray-900">
                                                                                {
                                                                                    item.name
                                                                                }
                                                                            </p>
                                                                            <p className="mt-1 text-sm text-gray-500">
                                                                                {
                                                                                    item.description
                                                                                }
                                                                            </p>
                                                                        </div>
                                                                    </a>
                                                                ),
                                                            )}
                                                        </div>
                                                    </div>
                                                </Popover.Panel>
                                            </Transition>
                                        </>
                                    )}
                                </Popover>
                                <a href="#" className="btn">
                                    <FormattedMessage
                                        id="menu.popular"
                                        defaultMessage="Popular Cities"
                                    />
                                </a>
                                <a
                                    href="#"
                                    className="btn hidden xl:inline-flex"
                                >
                                    <FormattedMessage
                                        id="menu.covid19"
                                        defaultMessage="Covid 19"
                                    />
                                </a>

                                <Popover className="relative">
                                    {({open}) => (
                                        <>
                                            <Popover.Button
                                                className={classNames(
                                                    open
                                                        ? 'bg-white text-gray-900'
                                                        : ' text-gray-500',
                                                    'btn',
                                                )}
                                            >
                                                <span>
                                                    <FormattedMessage
                                                        id="menu.more"
                                                        defaultMessage="More"
                                                    />
                                                </span>
                                                <ChevronDownIcon
                                                    className={classNames(
                                                        open
                                                            ? 'text-gray-600'
                                                            : 'text-gray-400',
                                                        'ml-2 h-5 w-5 group-hover:text-gray-500',
                                                    )}
                                                    aria-hidden="true"
                                                />
                                            </Popover.Button>

                                            <Transition
                                                as={Fragment}
                                                enter="transition ease-out duration-200"
                                                enterFrom="opacity-0 translate-y-1"
                                                enterTo="opacity-100 translate-y-0"
                                                leave="transition ease-in duration-150"
                                                leaveFrom="opacity-100 translate-y-0"
                                                leaveTo="opacity-0 translate-y-1"
                                            >
                                                <Popover.Panel className="absolute left-1/2 z-10 mt-3 w-screen max-w-xs -translate-x-1/2 transform px-2 sm:px-0">
                                                    <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                                                        <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                                                            {moreLinks.map(
                                                                (item) => (
                                                                    <a
                                                                        key={
                                                                            item.name
                                                                        }
                                                                        href={
                                                                            item.href
                                                                        }
                                                                        className="-m-3 block rounded-md p-3 hover:bg-gray-50"
                                                                    >
                                                                        <p className="text-base font-medium text-gray-900">
                                                                            {
                                                                                item.name
                                                                            }
                                                                        </p>
                                                                        <p className="mt-1 text-sm text-gray-500">
                                                                            {
                                                                                item.description
                                                                            }
                                                                        </p>
                                                                    </a>
                                                                ),
                                                            )}
                                                        </div>
                                                    </div>
                                                </Popover.Panel>
                                            </Transition>
                                        </>
                                    )}
                                </Popover>
                            </Popover.Group>
                            <div className="flex">
                                <div className="flex items-center">
                                    <a href="#" className="btn">
                                        <img
                                            src={`/flags/PNG/${locale.flag}.png`}
                                            alt={`${locale.domain} site`}
                                        />
                                        <ChevronDownIcon
                                            className={classNames(
                                                'text-gray-400',
                                                'ml-2 h-5 w-5 group-hover:text-gray-500',
                                            )}
                                            aria-hidden="true"
                                        />
                                    </a>
                                </div>
                                <div className="flex items-center">
                                    <a href="#" className="btn  xl:px-4">
                                        GBP
                                        <ChevronDownIcon
                                            className={classNames(
                                                'text-gray-400',
                                                'ml-2 h-5 w-5 group-hover:text-gray-500',
                                            )}
                                            aria-hidden="true"
                                        />
                                    </a>
                                </div>
                                <div className="flex items-center">
                                    <a href="#" className="btn-primary xl:ml-8">
                                        <FormattedMessage
                                            id="menu.manage"
                                            defaultMessage="Manage Booking"
                                        />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Transition
                        as={Fragment}
                        enter="duration-200 ease-out"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="duration-100 ease-in"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <Popover.Panel
                            focus
                            className="absolute inset-x-0 top-0 origin-top-right transform p-2 transition md:hidden"
                        >
                            <div className="divide-y-2 divide-gray-50 rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                                <div className="px-5 pt-5 pb-6">
                                    <div className="flex items-center justify-between">
                                        <div>
                                            <img
                                                className="h-8 w-auto"
                                                src="https://tailwindui.com/img/logos/workflow-mark-pink-600.svg"
                                                alt="ClickBookDrive"
                                            />
                                        </div>
                                        <div className="-mr-2">
                                            <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-pink-500">
                                                <span className="sr-only">
                                                    <FormattedMessage
                                                        id="menu.menu.close"
                                                        defaultMessage="Close Menu"
                                                    />
                                                </span>
                                                <XIcon
                                                    className="h-6 w-6"
                                                    aria-hidden="true"
                                                />
                                            </Popover.Button>
                                        </div>
                                    </div>
                                    <div className="mt-6">
                                        <nav className="grid gap-6">
                                            {menuLocations.map((item) => (
                                                <a
                                                    key={item.name}
                                                    href={item.href}
                                                    className={classNames(
                                                        item.hideMobile
                                                            ? 'hidden'
                                                            : '',
                                                        '-m-3 flex  items-center rounded-lg p-3 hover:bg-gray-50',
                                                    )}
                                                >
                                                    <div className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-md bg-pink-500 text-white">
                                                        <item.icon
                                                            className="h-6 w-6"
                                                            aria-hidden="true"
                                                        />
                                                    </div>
                                                    <div className="ml-4 text-base font-medium text-gray-900">
                                                        {item.name}
                                                    </div>
                                                </a>
                                            ))}
                                        </nav>
                                    </div>
                                </div>
                                <div className="py-6 px-5">
                                    <div className="grid grid-cols-2 gap-4">
                                        <a
                                            href="#"
                                            className="text-base font-medium text-gray-900 hover:text-gray-700"
                                        >
                                            <FormattedMessage
                                                id="menu.popular"
                                                defaultMessage="Popular Cities"
                                            />
                                        </a>

                                        <a
                                            href="#"
                                            className="text-base font-medium text-gray-900 hover:text-gray-700"
                                        >
                                            <FormattedMessage
                                                id="menu.covid19"
                                                defaultMessage="Covid 19"
                                            />
                                        </a>

                                        {moreLinks.map((item) => (
                                            <a
                                                key={item.name}
                                                href={item.href}
                                                className="text-base font-medium text-gray-900 hover:text-gray-700"
                                            >
                                                {item.name}
                                            </a>
                                        ))}
                                    </div>
                                    <div className="mt-6">
                                        <a
                                            href="#"
                                            className="btn-primary w-full"
                                        >
                                            <FormattedMessage
                                                id="menu.manage"
                                                defaultMessage="Manage Booking"
                                            />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </Popover.Panel>
                    </Transition>
                </Popover>
            </div>
        </div>
    );
}
