import {ReactElement} from 'react';

export default function Logo(): ReactElement {
    return (
        <a href="#" className="flex">
            <span className="sr-only">Workflow</span>
            <img
                className="h-8 w-auto sm:h-10"
                src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg"
                alt=""
            />
        </a>
    );
}
