import {ChevronDownIcon} from '@heroicons/react/solid';
import {ReactElement} from 'react';
import {FormattedMessage} from 'react-intl';

import SocialIcons from '../Icons/social';

const navigation = {
    uk: [
        {name: 'All UK', href: '#'},
        {name: 'Heathrow', href: '#'},
        {name: 'Glasgow', href: '#'},
        {name: 'Edinburgh', href: '#'},
        {name: 'Belfast', href: '#'},
        {name: 'Manchester ', href: '#'},
    ],
    europe: [
        {name: 'Spain', href: '#'},
        {name: 'Italy', href: '#'},
        {name: 'Malta', href: '#'},
        {name: 'Portugal', href: '#'},
        {name: 'France', href: '#'},
        {name: 'Croatia', href: '#'},
    ],
};

export default function Footer(): ReactElement {
    return (
        <footer className="bg-gray-50" aria-labelledby="footer-heading">
            <h2 id="footer-heading" className="sr-only">
                <FormattedMessage id="footer.title" defaultMessage="Footer" />
            </h2>
            <div className="mx-auto max-w-7xl py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
                <div className="pb-8 xl:grid xl:grid-cols-5 xl:gap-8">
                    <div className="grid grid-cols-2 gap-8 xl:col-span-4">
                        <div className="md:grid md:grid-cols-2 md:gap-8">
                            <div>
                                <h3 className="text-sm font-semibold uppercase tracking-wider text-gray-400">
                                    <FormattedMessage
                                        id="footer.menu1.title"
                                        defaultMessage="Car Hire"
                                    />
                                </h3>
                                <ul className="mt-4 space-y-4">
                                    <li>
                                        <a
                                            href="#"
                                            className="text-base text-gray-500 hover:text-gray-900"
                                        >
                                            <FormattedMessage
                                                id="footer.carhire.longterm"
                                                defaultMessage="Long term"
                                            />
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="#"
                                            className="text-base text-gray-500 hover:text-gray-900"
                                        >
                                            <FormattedMessage
                                                id="footer.carhire.peopleCarriers"
                                                defaultMessage="People Carriers"
                                            />
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="#"
                                            className="text-base text-gray-500 hover:text-gray-900"
                                        >
                                            <FormattedMessage
                                                id="footer.carhire.oneway"
                                                defaultMessage="One Way"
                                            />
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="#"
                                            className="text-base text-gray-500 hover:text-gray-900"
                                        >
                                            <FormattedMessage
                                                id="footer.carhire.automatics"
                                                defaultMessage="Automatics"
                                            />
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="#"
                                            className="text-base text-gray-500 hover:text-gray-900"
                                        >
                                            <FormattedMessage
                                                id="footer.carhire.excess"
                                                defaultMessage="Car Hire Excess"
                                            />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div className="mt-12 md:mt-0">
                                <h3 className="text-sm font-semibold uppercase tracking-wider text-gray-400">
                                    <FormattedMessage
                                        id="footer.menu2.title"
                                        defaultMessage="UK"
                                    />
                                </h3>
                                <ul className="mt-4 space-y-4">
                                    {navigation.uk.map((item) => (
                                        <li key={item.name}>
                                            <a
                                                href={item.href}
                                                className="text-base text-gray-500 hover:text-gray-900"
                                            >
                                                {item.name}
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <div className="md:grid md:grid-cols-2 md:gap-8">
                            <div>
                                <h3 className="text-sm font-semibold uppercase tracking-wider text-gray-400">
                                    <FormattedMessage
                                        id="footer.menu3.title"
                                        defaultMessage="Europe"
                                    />
                                </h3>
                                <ul className="mt-4 space-y-4">
                                    {navigation.europe.map((item) => (
                                        <li key={item.name}>
                                            <a
                                                href={item.href}
                                                className="text-base text-gray-500 hover:text-gray-900"
                                            >
                                                {item.name}
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div className="mt-12 md:mt-0">
                                <h3 className="text-sm font-semibold uppercase tracking-wider text-gray-400">
                                    <FormattedMessage
                                        id="footer.menu4.title"
                                        defaultMessage="Legal"
                                    />
                                </h3>
                                <ul className="mt-4 space-y-4">
                                    <li>
                                        <a
                                            href="#"
                                            className="text-base text-gray-500 hover:text-gray-900"
                                        >
                                            <FormattedMessage
                                                id="footer.legal.claim"
                                                defaultMessage="Claim"
                                            />
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="#"
                                            className="text-base text-gray-500 hover:text-gray-900"
                                        >
                                            <FormattedMessage
                                                id="footer.legal.privacy"
                                                defaultMessage="Privacy"
                                            />
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="#"
                                            className="text-base text-gray-500 hover:text-gray-900"
                                        >
                                            <FormattedMessage
                                                id="footer.legal.terms"
                                                defaultMessage="Terms"
                                            />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="mt-12 xl:mt-0">
                        <h3 className="text-sm font-semibold uppercase tracking-wider text-gray-400">
                            <FormattedMessage
                                id="footer.locale.title"
                                defaultMessage="Language & Currency"
                            />
                        </h3>
                        <form className="mt-4 sm:max-w-xs">
                            <fieldset className="w-full">
                                <label htmlFor="language" className="sr-only">
                                    <FormattedMessage
                                        id="footer.locale.language.title"
                                        defaultMessage="Language"
                                    />
                                </label>
                                <div className="relative">
                                    <select
                                        id="language"
                                        name="language"
                                        className="block w-full appearance-none rounded-md border border-gray-300 bg-white bg-none py-2 pl-3 pr-10 text-base text-gray-900 focus:border-pink-500 focus:outline-none focus:ring-pink-500 sm:text-sm"
                                        defaultValue="English"
                                    >
                                        <option>English</option>
                                        <option>French</option>
                                        <option>German</option>
                                        <option>Japanese</option>
                                        <option>Spanish</option>
                                    </select>
                                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2">
                                        <ChevronDownIcon
                                            className="h-4 w-4 text-gray-400"
                                            aria-hidden="true"
                                        />
                                    </div>
                                </div>
                            </fieldset>
                            <fieldset className="mt-4 w-full">
                                <label htmlFor="currency" className="sr-only">
                                    <FormattedMessage
                                        id="footer.locale.currency.title"
                                        defaultMessage="Currency"
                                    />
                                </label>
                                <div className="relative">
                                    <select
                                        id="currency"
                                        name="currency"
                                        className="block w-full appearance-none rounded-md border border-gray-300 bg-white bg-none py-2 pl-3 pr-10 text-base text-gray-900 focus:border-pink-500 focus:outline-none focus:ring-pink-500 sm:text-sm"
                                        defaultValue="AUD"
                                    >
                                        <option>ARS</option>
                                        <option>AUD</option>
                                        <option>CAD</option>
                                        <option>CHF</option>
                                        <option>EUR</option>
                                        <option>GBP</option>
                                        <option>JPY</option>
                                        <option>USD</option>
                                    </select>
                                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2">
                                        <ChevronDownIcon
                                            className="h-4 w-4 text-gray-400"
                                            aria-hidden="true"
                                        />
                                    </div>
                                </div>
                            </fieldset>
                        </form>
                    </div>
                </div>
                <div className="border-t border-gray-200 pt-8 lg:flex lg:items-center lg:justify-between xl:mt-0">
                    <div>
                        <h3 className="text-sm font-semibold uppercase tracking-wider text-gray-400">
                            <FormattedMessage
                                id="footer.newsletter.title"
                                defaultMessage="Subscribe to our monthly newsletter."
                            />
                        </h3>
                        <p className="mt-2 text-base text-gray-500">
                            <FormattedMessage
                                id="footer.newsletter.description"
                                defaultMessage="Sign up now for hand-picked car hire deals, articles and latest travel tips, sent straight to your inbox"
                            />
                        </p>
                    </div>
                    <form className="mt-4 sm:flex sm:max-w-md lg:mt-0">
                        <label htmlFor="email-address" className="sr-only">
                            <FormattedMessage
                                id="footer.newsletter.label"
                                defaultMessage="Email address"
                            />
                        </label>
                        <input
                            type="email"
                            name="email-address"
                            id="email-address"
                            autoComplete="email"
                            required
                            className="w-full min-w-0 appearance-none rounded-md border border-gray-300 bg-white py-2 px-4 text-base text-gray-900 placeholder-gray-500 focus:border-pink-500 focus:placeholder-gray-400 focus:outline-none focus:ring-pink-500 sm:max-w-xs"
                            placeholder="Enter your email"
                        />
                        <div className="mt-3 rounded-md sm:mt-0 sm:ml-3 sm:flex-shrink-0">
                            <button type="submit" className="btn-primary">
                                <FormattedMessage
                                    id="footer.newsletter.button"
                                    defaultMessage="Subscribe"
                                />
                            </button>
                        </div>
                    </form>
                </div>
                <div className="mt-8 border-t border-gray-200 pt-8 md:flex md:items-center md:justify-between">
                    <div className="flex space-x-6 md:order-2">
                        {SocialIcons.map((item) => (
                            <a
                                key={item.name}
                                href={item.href}
                                className="text-gray-400 hover:text-gray-500"
                            >
                                <span className="sr-only">{item.name}</span>
                                <item.icon
                                    className="h-6 w-6"
                                    aria-hidden="true"
                                />
                            </a>
                        ))}
                    </div>
                    <p className="mt-8 text-base text-gray-400 md:order-1 md:mt-0">
                        <FormattedMessage
                            id="footer.copyright"
                            defaultMessage="© 2022 ClickBookDrive. All rights reserved."
                        />
                    </p>
                </div>
            </div>
        </footer>
    );
}
